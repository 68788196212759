body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li.draggable {
  background-color: lightblue;
  width: 200px;
  height: 250px;
  text-align: left;
  border: solid 1px;
  padding-left: 10px;
  border-style: ridge;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.PhoneInputInput {
  border: 1px #CCCCCC solid;
  padding: 15px;
  background: transparent;
  border-radius: 5px;
}
