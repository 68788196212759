.magazine {
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.magazine .page {
  height: 100%;
}

.magazine .page img {
  max-width: 100%;
  height: 100%;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.jodit-toolbar__box {
  display: none !important;
}

/* jodit editor */
.jodit-toolbar-editor-collection {
  display: none !important;
}

.p-16 {
  padding: 16px !important;
}

.m3 {
  margin: 3px;
}

.m5 {
  margin: 5px;
}
